<template>
  <register-form />
</template>

<script>
export default {
  components: {
    registerForm: () => import('@/components/auth/RegisterForm.vue'),
  },
}
</script>
